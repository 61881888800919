@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GT-Walsheim;
  src: url("./assets/fonts/GT-Walsheim-Regular.woff2") format("woff2");
}

@font-face {
  font-family: GT-Walsheim-Bold;
  src: url("./assets/fonts/GT-Walsheim-Bold.woff2") format("woff2");
}

@font-face {
  font-family: SF-Pro-Regular;
  src: url("./assets/fonts/sf-pro-display_regular.woff2") format("woff2");
}
@font-face {
  font-family: SF-Pro-Semibold;
  src: url("./assets/fonts/sf-pro-display_semibold.woff2") format("woff2");
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.circle {
  position: fixed;
  z-index: 100;
  display: flex;
  border-radius: 200px;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  font-family: "Playfair Display", serif;
  font-style: italic;
}
